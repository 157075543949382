import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import AppLogo2 from "../Icons/appLogo.png";
import Icon from "../Images/MicrosoftTeams-image (43).png";
import Icon2 from "../Images/gotoPlayground.png";
import Icon3 from "../Images/Contribute.png";
import Icon4 from "../Images/BeaContributor.png";
import { useMsal } from "@azure/msal-react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import SendFeedbackPage from "./SendFeedbackPage";
import "../Common.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Tooltip from '@mui/material/Tooltip';
import { Container, Row, Col } from "react-grid-system";
import Logout from "./Logout";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 10,
            top: 10,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function Header(props) {
  const { instance } = useMsal();
  const [email, setEmail] = useState("");
  const [canContribute, setCanContribute] = useState(true);
  const [inProgress, setInProgress] = useState(false);
  const [open, setOpen] = useState(false);

    //------ Logout Session After 2hrs ---------
    useEffect(() =>{
      clearAppSession();
    }, [])
  
    const clearAppSession = () => {
      const clearTime = setTimeout( () =>{
        localStorage.clear();  
      },7200000);
  
      return() => clearTimeout(clearTime);
    }
  //--------------- End ---------------------
  
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(()=>{
    localStorage.getItem('usermail')!=null || localStorage.getItem('username')!==null? setCanContribute(true) : setCanContribute(false)
  },[])

  useEffect(() => {
    const userEmail = instance.getAllAccounts()[0]?.username;
    setEmail(userEmail);

    if (userEmail) {
      fetch("https://prod.appstudioltts.com/api/requestpage/acceptedrequest")
        .then((response) => response.json())
        .then((acceptedRequestData) => {
          const isPresentInAcceptedRequest = acceptedRequestData.some(
            (item) => item.Email === userEmail
          );

          if (isPresentInAcceptedRequest) {
            setCanContribute(true);
          } else {
            fetch("https://prod.appstudioltts.com/api/skills/skillsview")
              .then((response) => response.json())
              .then((skillsViewData) => {
                const isPresentInSkillsView = skillsViewData.some(
                  (item) => item.Email === userEmail
                );

                if (isPresentInSkillsView) {
                  setInProgress(true);
                } else {
                  setCanContribute(false);
                  setInProgress(false);
                }
              })
              .catch((error) => {
                console.error("Error fetching skillsview API:", error);
                setCanContribute(false);
                setInProgress(false);
              });
          }
        })
        .catch((error) => {
          console.error("Error fetching acceptedrequest API:", error);
          setCanContribute(false);
          setInProgress(false);
        });
    }
  }, []);

  const handleInProgressClick = () => {
    // Show an error notification
    toast.error("Your Approval request is in progress", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
console.log("UserLogged in : ",props)
  return (
    <>
  
      <AppBar position="static" className="HeaderCss">
        <Toolbar>
          <span>
            <img className="AppLogo" src={AppLogo2} alt="App Logo" />
          </span>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} className="textApp" >
            App Studio Community
          </Typography>
         <span className="buttons-span-width">
         {canContribute && !inProgress ? (
            <NavLink to="/contribute">
              <Tooltip title="Contribute">
              <Button
                variant="contained"
                className="headerContributeBtn hide-on-small-screens"
              >
                <img
                  // className="AppLogo3"
                  src={Icon3}
                  className="newHeaderimageButton"
                  alt="Contribute Icon"
                />
                <span className="hide-on-small-screens">Contribute</span>
              </Button>
              </Tooltip>
            </NavLink>
          ) : inProgress ? (
            <Button
              variant="contained"
              className="headerAppPendBtn hide-on-small-screens"
              onClick={handleInProgressClick}
            >
              <span className="hide-on-small-screens">Approval pending</span>
            </Button>
          ) : (
            <NavLink to="/contributorPage">
              <Tooltip title="Be a Contributor">
              <Button
                variant="contained"
                className="headerBeacontBtn hide-on-small-screens"
              >
                <img
                  src={Icon4}
                  className="newHeaderimageButton"
                  alt="Contribute Icon"
                />
                <span className="hide-on-small-screens">Be a Contributor</span>
              </Button>
              </Tooltip>
              
            </NavLink>
          )}


          {}
          <Tooltip title="Go to Playground">
          <Button
            variant="contained"
            className="headerGotoplBtn hide-on-small-screens"
            onClick={() =>
              window.open(
                "https://playground.appstudioltts.com/#/",
                "blank"
              )
            }
          >
            
            <img
              src={Icon2}
              className="headerGoToplIcon "
              alt="Playground Icon"
            />
           
            <span className="hide-on-small-screens">Go To Playground</span>
          </Button>
          </Tooltip>
          
          <Tooltip title="Send Feedback">
          <Button
            onClick={() => setOpen(true)}
            className="headerSendFdBtn hide-on-small-screens"
          >
            <span>
            <img className="AppLogo2" src={Icon} alt="Icon" />
          </span>
           <span className="hide-on-small-screens"> Send Feedback</span>
          </Button>
          </Tooltip>
           </span>
            {/* ----- Logout ----- */}
            <span>
              <Logout />
            </span>
        </Toolbar>
      </AppBar>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          className="popup-heading hide-on-small-screens"
        >
          Send Feedback
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography className="p-font" gutterBottom>
            <div>
              <SendFeedbackPage />
            </div>
          </Typography>
        </DialogContent>
      </BootstrapDialog>
      <ToastContainer />
    </>
  );
}






























